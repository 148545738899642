import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import Finalist from "../../components/finalist"

import LeftMenu from './leftMenu'
const Winners = () => (
  <Layout>
    <SEO title="National Student Essay Competition | Winners" />

    <div className="subpageContainer">

      <LeftMenu active="finalists" />
      <div className="body">
         
        <h1 style={{marginTop: '55px'}}>Congratulations to our winners!</h1>
        
        <h2>National Student Essay Competition Winners </h2>
        <p>
        {/* <a href="https://forms.gle/Wgfcbyych8kuUNwF7" target="_blank">Vote for the essay you think is the best!</a><br /> */}
        Recipients each of $5,000 from the Boston Globe Foundation.</p>

        <h3>UNIVERSITY </h3>

        <div className="finalist-group">
          <Finalist name="Ali Sullivan" school="University of Virginia" location="Charlottesville, Virginia" link="ali-sullivan.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Ali+Sullivan" />
        </div>
        
        <h3>GRADES 9-12</h3>
        <div className="finalist-group">
          <Finalist name="Annelise Best" school="Frisco Liberty High School, 12th Grade" location="Frisco, Texas" link="annalise-best.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Annelise+Best" />
        </div>
        
        <h3>GRADES 6-8</h3>
        <div class="finalist-group">
          <Finalist name="Eric Lu" school="West High School, 8th Grade" location="Salt Lake City, Utah" link="eric-lu-8th.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Eric+Lu" />
        </div>

        <h3>Winner of the Westminster College Full Four-year Scholarship</h3>
        <div class="finalist-group">
          <Finalist name="Annelise Best" />
        </div>

        <h3>Winner of the Popular Vote</h3>
        <h4>Recipient of $1,000 from Mary Kay Lazarus Public Relations</h4>
        <div class="finalist-group">
          <Finalist name="Marcus Lewis, Jr." school="Rodeo Palms JH, 8th grade" location="Rosharon, Texas" link="marcus-lewis-jr.pdf" voteLink="https://docs.google.com/forms/d/e/1FAIpQLSccr_O7VGXSACbtOxo1PW7KeKO5fBeA_XGaqIMLzYtlY9dRXw/viewform?usp=pp_url&entry.256125240=Essay+by+Marcus+Lewis,+Jr." />
        </div>
      </div>
    </div>

    

  </Layout>
)

export default Winners
